import { Location, useLocation } from 'react-router';
import { useCallbackRef } from '@synoptic/ui-kit/utils/use-callback-ref.js';
import { useEffect, useRef, useState } from 'react';
import { StateSnapshot, VirtuosoHandle } from 'react-virtuoso';

const feedPositions: Map<string, StateSnapshot> = new Map();

export const hasFeedPosition = (key: string) => feedPositions.has(key);
export const removeFeedPosition = (key: string) => feedPositions.delete(key);

const defaultGetKey = (location: Location) => location.key;

export const useRestoreFeed = ({
  getKey = defaultGetKey,
  onRestore,
}: {
  getKey?: (location: Location) => string;
  onRestore?: (args: { fetchOptions: Partial<Request> }) => void;
} = {}) => {
  const virtuosoRef = useRef<VirtuosoHandle>(null);
  const loc = useLocation();
  const [mounted, setMounted] = useState(false);

  const key = getKey(loc);

  const [restoreStateFrom] = useState(() => feedPositions.get(key));

  const refresh = useCallbackRef(onRestore);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (feedPositions.get(key)) {
      const abortController = new AbortController();

      refresh({ fetchOptions: { signal: abortController.signal } });

      return () => {
        abortController.abort();
      };
    }
  }, [key, refresh]);

  useEffect(() => {
    const virtuoso = virtuosoRef.current;

    return () => {
      if (virtuoso) {
        virtuoso.getState((state) => feedPositions.set(key, state));
      }
    };
  }, [key]);

  return {
    virtuosoRef,
    restoreStateFrom,

    /** pass this to feed container to avoid flash of unscrolled content https://github.com/petyosi/react-virtuoso/issues/957#issuecomment-1653179355 */
    style:
      restoreStateFrom && !mounted
        ? { minHeight: restoreStateFrom.scrollTop }
        : undefined,
  };
};
